var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import Button from '@shared/ui/buttons/Button';
import { ExchangeSidebar, getFormInitValues, removePortfolio, selectPortfolio, } from '@entities/exchange';
import ClosePortfolioSidebar from '../ClosePortfolioSidebar';
const ClosePortfolioButton = (_a) => {
    var { selectedPortfolioId } = _a, rest = __rest(_a, ["selectedPortfolioId"]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const dispatch = useDispatch();
    const handleOpenSidebar = () => {
        dispatch(selectPortfolio(selectedPortfolioId));
        dispatch(getFormInitValues(ExchangeSidebar.ClosePortfolio));
        setIsSidebarOpen(true);
    };
    const handleClose = () => {
        setIsSidebarOpen(false);
        dispatch(removePortfolio());
        dispatch(getFormInitValues(ExchangeSidebar.ClosePortfolio));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            , Object.assign({}, rest, { onClick: handleOpenSidebar })), _jsx(Sidebar, { isOpen: isSidebarOpen, children: _jsx(ClosePortfolioSidebar, { handleClose: handleClose }) })] }));
};
export default ClosePortfolioButton;

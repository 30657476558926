const getPortfolioStatusPriority = (portfolio) => {
    if (portfolio.has_error) {
        return 1;
    }
    if (!portfolio.assessmentPassed) {
        return 2;
    }
    return 3;
};
// sorting portfolios by status priority:
// - first, display portfolios with errors
// - second, display portfolios that are in assessment review
// - other portfolios
const sortPortfoliosByStatusPriority = (portfolios) => portfolios.toSorted((a, b) => {
    const aPriority = getPortfolioStatusPriority(a);
    const bPriority = getPortfolioStatusPriority(b);
    return aPriority - bPriority;
});
export { 
// eslint-disable-next-line import/prefer-default-export
sortPortfoliosByStatusPriority, };

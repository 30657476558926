import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProfilePortfolioCard } from '@entities/portfolio';
import { PortfolioEdit } from '@features/portfolio/portfolio-edit';
const CarouselListContent = ({ isLoading = false, onEditClose, onPortfolioAction, onPortfolioSelect, portfoliosList, portfolioToEdit = undefined, selectedPortfolioId = undefined, }) => {
    if (isLoading) {
        return _jsxs(_Fragment, { children: [_jsx(ProfilePortfolioCard, { viewType: 'list', isLoading: true }, 'first-skeleton'), _jsx(ProfilePortfolioCard, { viewType: 'list', isLoading: true }, 'second-skeleton'), _jsx(ProfilePortfolioCard, { viewType: 'list', isLoading: true }, 'third-skeleton')] });
    }
    return portfoliosList.map((portfolio) => (
    // in list view we display PortfolioEdit view within other list items
    (portfolioToEdit === null || portfolioToEdit === void 0 ? void 0 : portfolioToEdit.id) === portfolio.id ? (_jsx(PortfolioEdit, { portfolio: portfolioToEdit, onEditClose: onEditClose }, portfolioToEdit.id)) : (_jsx(ProfilePortfolioCard, { portfolio: portfolio, viewType: 'list', selected: portfolio.id === selectedPortfolioId, onClick: () => onPortfolioSelect(portfolio.id), onAction: onPortfolioAction }, portfolio.id))));
};
export default CarouselListContent;

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProfilePortfolioCard } from '@entities/portfolio';
import { PortfolioEdit } from '@features/portfolio/portfolio-edit';
import { IsDefined } from '@shared/lib/js-ts';
const CarouselCardContent = ({ isLoading = false, portfolioToEdit = undefined, onEditClose, portfoliosList, selectedPortfolioId = undefined, onPortfolioSelect, onPortfolioAction, }) => {
    if (isLoading) {
        return _jsxs(_Fragment, { children: [_jsx(ProfilePortfolioCard, { viewType: 'card', isLoading: true }, 'first-skeleton'), _jsx(ProfilePortfolioCard, { viewType: 'card', isLoading: true }, 'second-skeleton'), _jsx(ProfilePortfolioCard, { viewType: 'card', isLoading: true }, 'third-skeleton')] });
    }
    if (IsDefined(portfolioToEdit)) {
        // in card view we replace whole carousel with PortfolioEditView
        return (_jsx(PortfolioEdit, { portfolio: portfolioToEdit, onEditClose: onEditClose }));
    }
    return portfoliosList.map((portfolio) => (_jsx(ProfilePortfolioCard, { portfolio: portfolio, viewType: 'card', selected: portfolio.id === selectedPortfolioId, onClick: () => onPortfolioSelect(portfolio.id), onAction: onPortfolioAction }, portfolio.id)));
};
export default CarouselCardContent;
